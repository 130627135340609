import { useQuery } from '@tanstack/react-query';
import useSupabase from '../supabase';

export function useGetRisingStars(skip: number, num: number) {
  const client = useSupabase();
  const queryKey = ['get_rising_stars', skip, num];

  const queryFn = async (): Promise<RisingStar[]> => {
    const { data, error } = await client.rpc('get_rising_stars', {
      skip,
      num,
    });
    if (error) throw new Error(error.message);
    return data as RisingStar[];
  };

  return useQuery<RisingStar[]>({
    queryKey,
    queryFn,
  });
}
