import { useQuery } from '@tanstack/react-query';
import useSupabase from '../supabase';

export function useGetTopCreators(skip: number, num: number) {
  const client = useSupabase();
  const queryKey = ['get_top_creators', skip, num];

  const queryFn = async (): Promise<TopCreator[]> => {
    const { data, error } = await client.rpc('get_top_creators', {
      skip,
      num,
    });
    if (error) throw new Error(error.message);
    return data as TopCreator[];
  };

  return useQuery<TopCreator[]>({
    queryKey,
    queryFn,
  });
}
