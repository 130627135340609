import { Box, Grid, Typography } from '@mui/material';
import LeaderboardSectionSkeleton from './LeaderboardSectionSkeleton';
import { AlertIcon } from '../icons';

const LeaderboardSkeletonOverlay = () => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
    }}
  >
    <Grid container spacing={5} pb={6}>
      <LeaderboardSectionSkeleton title="Top creators" />
      <LeaderboardSectionSkeleton title="Rising stars" />
    </Grid>
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background:
          'linear-gradient(360deg, #090C11 1.61%, rgba(9, 12, 17, 0) 93.11%)',
        alignItems: 'center',
        pt: '200px',
      }}
    >
      <AlertIcon />
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '24px',
          color: '#F0F5FA',
          lineHeight: '28px',
          textAlign: 'center',
        }}
      >
        Complete your profile setup to unlock the Altcoinist platform 
        <br />and start monetizing your community onchain!
      </Typography>
    </Box>
  </Box>
);

export default LeaderboardSkeletonOverlay;
