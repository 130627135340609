import { useState } from 'react';

import { Box, Button, Grid, Typography, Tooltip } from '@mui/material';
import { InfoIcon } from '../components/icons/InfoIcon';

import { ICreator } from '../types/ICreator';

import { ChevronRight } from './icons/chevronRight';
import SuggestedUserCard, {
  SuggestedUserProfile,
} from './shared/Cards/SugestedUserCard';
import AllCreatorsDrawer from './shared/Drawer/AllCreatorsDrawer';

interface ILeaderboardSectionProps {
  title: string;
  topCreators: TopCreator[];
  currentUsername: string | undefined;
  supportDiffs?: boolean;
  higlightTopItem?: boolean;
  renderPosition: (index: number) => number;
  useQueryHook: (skip: number, num: number) => any;
  tooltipText?: string;
}

export type TopCreator = SuggestedUserProfile & Pick<ICreator, 'subs'>;

export const LeaderboardSection = ({
  title,
  topCreators,
  renderPosition,
  currentUsername,
  supportDiffs = false,
  higlightTopItem = true,
  useQueryHook,
  tooltipText,
}: ILeaderboardSectionProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const showViewAllButton = topCreators.length === 5;

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: { xs: '20px', md: '24px' },
            marginRight: '10px',
          }}
        >
          {title}
        </Typography>
        <Tooltip title={tooltipText} enterTouchDelay={0}>
          <Box>
            <InfoIcon color="#9B9FA3" />
          </Box>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 4,
          border: '1px solid #222426',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {topCreators.map((creator: TopCreator, index: number) => (
            <SuggestedUserCard
              key={creator.username}
              profile={creator}
              subscribers={creator.subs}
              hasBorderRadiusTop={index === 0}
              positionNumber={renderPosition(index)}
              isMe={!!currentUsername && creator.username === currentUsername}
              supportDiffs={supportDiffs}
              higlightTopItem={higlightTopItem}
              sharedRevenueRoc={creator.shared_revenue_roc}
              creatorRevenueRoc={creator.total_revenue_roc}
            />
          ))}
        </Box>
        <Box sx={{ p: 4 }}>
          {showViewAllButton && (
            <Button
              variant="text"
              sx={{ color: 'white' }}
              endIcon={<ChevronRight size={16} />}
              onClick={() => setIsDrawerOpen(true)}
            >
              View full list
            </Button>
          )}
        </Box>
      </Box>
      <AllCreatorsDrawer
        open={isDrawerOpen}
        toggleDrawer={(open: boolean) => setIsDrawerOpen(open)}
        title={title}
        currentUsername={currentUsername}
        supportDiffs={supportDiffs}
        higlightTopItem={higlightTopItem}
        useQueryHook={useQueryHook}
      />
    </Grid>
  );
};
