import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';

import { useGetLeaderboard } from '../../../api/users/useGetLeaderboard';
import { useGetRisingStars } from '../../../api/users/useGetRisingStars';
import { useGetTopCreators } from '../../../api/users/useGetTopCreators';
import { GlobalLoading } from '../../../components/GlobalLoading';
import { KolGamesIcon, LearningIcon } from '../../../components/icons';
import { LeaderboardSection } from '../../../components/LeaderboardSection';
import SearchAi from '../../../components/SeaarchAi';
import ActionCard from '../../../components/shared/Cards/ActionCard';
import LeaderboardSkeletonOverlay from '../../../components/Skeletons/LeaderboardSkeletonOverlay';
import UserCardsOnboardSteps from '../../../components/UserCardsOnboardSteps';
import {
  LEARN_MORE_KOL_GAMES_LINK,
  LEARN_MORE_LEADERBOARD_RANK_LINK,
} from '../../../constants/externalLinks';
import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';
import { useProfileSetupProgress } from '../../../providers/ProfileSetupProgressProvider';
import { UserType } from '../../../types/UserType';

export function LeaderboardPageContent() {
  const { data: userData, isLoading: isLoadingUserData } = useCurrentUserData();
  const userType: UserType = userData?.user_type;

  const { isProfileSetupCompleted, isLoading: isLoadingProfileSetupCompleted } =
    useProfileSetupProgress();
  const disableActionsForCreators =
    !isProfileSetupCompleted && userType === UserType.CREATOR;

  const { data: leaderboardData, isLoading: isLoadingLeaderboardData } =
    useGetLeaderboard();

  if (
    isLoadingUserData ||
    isLoadingProfileSetupCompleted ||
    isLoadingLeaderboardData
  ) {
    return <GlobalLoading />;
  }

  const topCreators = leaderboardData?.top_creators;
  const risingStars = leaderboardData?.rising_stars;

  if (!topCreators || !risingStars) {
    return null;
  }

  const tryToOpenExternalLink = (
    link: string | undefined,
    target: string = '_blank',
  ) => {
    if (!link) {
      return;
    }

    window.open(link, target);
  };

  const handleLearnMoreKolGames = () => {
    tryToOpenExternalLink(LEARN_MORE_KOL_GAMES_LINK);
  };

  const handleLearnMoreLeaderboardRanking = () => {
    tryToOpenExternalLink(LEARN_MORE_LEADERBOARD_RANK_LINK);
  };

  return (
    <Stack
      direction="column"
      sx={{
        flex: 1,
        overflowY: 'auto',
        width: { xs: '100vw', md: '100%' },
        height: '100%',
        gap: 6,
        px: { xs: 5, md: 0 },
        pb: { xs: 20, md: 10 },
        bgcolor: 'transparent',
        '&::-webkit-scrollbar': { backgroundColor: 'transparent' },
      }}
    >
      {!isProfileSetupCompleted && userData && (
        <UserCardsOnboardSteps isCreator={userType === UserType.CREATOR} />
      )}

      <Box
        id="creator-leaderboard"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          pt: disableActionsForCreators ? 14 : 0,
        }}
      >
        <Typography
          fontSize={{ xs: '24px', md: '32px' }}
          color={disableActionsForCreators ? '#9B9FA3' : ''}
          pb={{ xs: 5, md: 0 }}
        >
          Creator Leaderboard
        </Typography>
        {!disableActionsForCreators && (
          <SearchAi
            sx={{
              width: { xs: '100%', md: '272px' },
              height: '44px',
              p: 0,
              pl: 4,
              alignItems: 'center',
            }}
          />
        )}
      </Box>
      <Divider sx={{ display: { xs: 'none', md: 'block' } }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 16,
          pt: 4,
          position: 'relative',
        }}
      >
        {disableActionsForCreators ? (
          <LeaderboardSkeletonOverlay />
        ) : (
          <Grid container spacing={{ xs: 10, md: 5 }} pb={6}>
            <LeaderboardSection
              title="Top creators"
              topCreators={topCreators}
              renderPosition={(index) => index + 1}
              currentUsername={userData?.username}
              useQueryHook={useGetTopCreators}
              tooltipText="The creators who have the highest Total TVL and Shared Revenue."
            />
            <LeaderboardSection
              title="Rising stars"
              topCreators={risingStars}
              renderPosition={(index) => index + 1}
              currentUsername={userData?.username}
              supportDiffs={true}
              higlightTopItem={false}
              useQueryHook={useGetRisingStars}
              tooltipText="Creators who are skyrocketing in ranks, based on the positions they have climbed in the last 7 days."
            />
          </Grid>
        )}
      </Box>
      {!disableActionsForCreators && (
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <ActionCard
              title="The Creator Games"
              description="Support your favorite creators and win together"
              image={<KolGamesIcon />}
              isLong
              comingSoon
            >
              <Button variant="outlined" onClick={handleLearnMoreKolGames}>
                Learn more
              </Button>
            </ActionCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ActionCard
              title="Altcoinist learning"
              description="How to rank in the leaderboard and get rewards"
              image={<LearningIcon />}
              isLong
            >
              <Button
                variant="outlined"
                onClick={handleLearnMoreLeaderboardRanking}
              >
                Learn more
              </Button>
            </ActionCard>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}
